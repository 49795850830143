import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import { Link, graphql } from "gatsby";
import HikakuTable from 'components/hikakuTableLoadable';
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import Socials from 'components/socials';
import BasicTable from "components/basicTable";
import FlatButton from "components/flatbutton";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/ryzen-intel"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/ryzen-intel.png"}) { ...eyecatchImg },
    allRyzenIntelJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`RyzenとIntelの違いを比較、どちちを選ぶべきか目的別に提示してみた`}</h1>
    <time itemProp="datePublished" dateTime="Sat Sep 14 2024 16:28:10 GMT+0900 (日本標準時)">更新日:2024年9月14日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="RyzenとIntelを比較" mdxType="Image" />
    <p>{`AMDのRyzenとIntelのCore iシリーズのどちらを選択するかは、パソコン購入時に非常に悩ましい問題である。`}</p>
    <p>{`RyzenとIntelの違いをひとことで言えば、`}<strong parentName="p"><em parentName="strong">{`マルチコア性能と低消費電力に優れるのがRyzenで、シングルコア性能と有力ソフトウェアのパフォーマンス最適化に優れるのがIntel`}</em></strong>{`である。`}</p>
    <p>{`本ページではRyzen、Intelの違いと、その違いが生じる理由も踏まえて解説し、これらの違いがゲーム、動画編集、オフィス作業、プログラミングなどに与える影響を解説する。`}</p>
    <p>{`これにより、RyzenとIntelのどちらであれ、納得してパソコンを購入できるようになることを目指す。なお厳密にはRyzenと比較するのはIntelではなく、Intel Core i シリーズ CPUであるが、長いのでIntelと略して話を進める。`}</p>
    <p>{`比較表で簡単に性能を比較したい人は、下記のボタンでページ下の表に行くか、`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`に行き確認して欲しい。`}</p>
    <div style={{
      textAlign: 'center',
      marginBottom: '10px'
    }}>
      <FlatButton onClick={() => document.querySelector('#hikaku').scrollIntoView()} className="top-margin" mdxType="FlatButton">Ryzen/Intel性能比較表へ</FlatButton>
    </div>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "RyzenとIntelの大まかな違い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Ryzen%E3%81%A8Intel%E3%81%AE%E5%A4%A7%E3%81%BE%E3%81%8B%E3%81%AA%E9%81%95%E3%81%84",
        "aria-label": "RyzenとIntelの大まかな違い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RyzenとIntelの大まかな違い`}</h2>
    <p>{`まず最初に断っておくと、Ryzen、Intelともデスクトップ向け、ノート向け、低消費電力版など多くの製品が発売されており、製品間の違いが非常に大きい。`}</p>
    <p>{`またそれぞれ毎年アーキテクチャが刷新され、今年のIntelはマルチスレッドが改善された、Ryzenは消費電力が改善されたなど、発売時期(世代)による差異もある。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`最終的にはRyzenかIntelかよりも、個々のCPUを`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`などで判断するのが良い`}</em></strong>{`。`}</p>
    <p>{`しかし開発体制やアーキテクチャの違いから、ある程度用途ごとに得手不得手が分かれることも事実であり、また`}<strong parentName="p"><em parentName="strong">{`比較表、すなわちベンチマーク結果だけでは判別できない特徴も存在する`}</em></strong>{`ため、全体としてのRyzenとIntelの特徴を抑えることは、購入の判断材料として有用であろう。`}</p>
    <p>{`それでは具体的な特徴を述べる。`}</p>
    <h3 {...{
      "id": "マルチコア性能のRyzenとシングルコア性能のIntel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9E%E3%83%AB%E3%83%81%E3%82%B3%E3%82%A2%E6%80%A7%E8%83%BD%E3%81%AERyzen%E3%81%A8%E3%82%B7%E3%83%B3%E3%82%B0%E3%83%AB%E3%82%B3%E3%82%A2%E6%80%A7%E8%83%BD%E3%81%AEIntel",
        "aria-label": "マルチコア性能のRyzenとシングルコア性能のIntel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マルチコア性能のRyzenとシングルコア性能のIntel`}</h3>
    <p>{`一般的に`}<strong parentName="p"><em parentName="strong">{`RyzenのCPUはマルチコア性能が高く複数のアプリケーションを効率良く動かす事ができる一方で、Intelはシングルコア性能が高く、単一のアプリケーション処理を高速に処理する`}</em></strong>{`ことができる。`}</p>
    <p>{`インターネットを閲覧しながら音楽を聞いたりExcelを操作するなどの作業は、同時に複数のアプリケーションが動くためCPUにとってマルチタスク(専門的には`}<em parentName="p">{`マルチスレッド`}</em>{`)となるが、それ以外にもファイルの圧縮・解凍、AI演算など、単一の作業と思えてもプログラミングとしてマルチコアを使用するものも多い。`}</p>
    <p>{`シングルコア性能かマルチコア性能、どちらが高速化に有利に働くかはおおよそ次のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>有利なコア</th>
      <th>具体的なタスク</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>シングルコア</td>
      <td>アプリの起動、Webブラウジングのサクサク感、ゲーム</td>
    </tr>
    <tr>
      <td>マルチコア</td>
      <td>複数アプリの同時実行、ファイルの解凍・圧縮、動画のエンコード・デコード、AI処理、画像のレンダリング</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "ソフトウェア最適化ではIntel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E6%9C%80%E9%81%A9%E5%8C%96%E3%81%A7%E3%81%AFIntel",
        "aria-label": "ソフトウェア最適化ではIntel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ソフトウェア最適化ではIntel`}</h3>
    <p>{`CPUの性能を十分に引き出すのはソフトウェアの役割である。たとえば複数のコアをどう並列に扱うか？CPUとGPU、その他の専用の処理を行うモジュールをいかに適材適所に分担させるか？などを考えて実装することでパフォーマンスは何倍にも跳ね上がる。`}</p>
    <p>{`ところが一般のアプリケーションエンジニアがCPUの特性を利用してプログラムを組むのは容易にできるものではない。`}</p>
    <p>{`これに対してインテルは`}<a href="https://software.intel.com/content/www/us/en/develop/tools/oneapi.html" target="_blank">{`oneAPI`}</a>{`や`}<a href="https://www.intel.co.jp/content/www/jp/ja/internet-of-things/solution-briefs/openvino-toolkit-product-brief.html" target="_blank">{`OpenVINO`}</a>{`といった`}<strong parentName="p"><em parentName="strong">{`ツールキットを準備することで簡単にCPUの性能を開発者が引き出せるようにしている`}</em></strong>{`。`}</p>
    <p>{`例えばAdobeのAIを駆使したソフトウェア機能`}<a href="https://www.adobe.com/jp/sensei.html" target="_blank">{`Adobe Sensei`}</a>{`はOpenVINOツールキットを使用したパフォーマンスの最適化が行われており、動画から不要なオブジェクトを消すなどインテリジェントな処理を高速で行うことができる。`}</p>
    <p>{`他にもGoogleやMicrosoftなど主要アプリケーションを抱えるベンダーに対してインテルCPUの性能を最大限に引き出せるよう綿密な連携を行いつつ開発を進めており、Google ChromeやMicrosoft Officeのパフォーマンスは高い。`}</p>
    <p>{`一方の`}<strong parentName="p"><em parentName="strong">{`AMDの開発人員はインテルと比べて小規模であり、アプリごとの最適化はそこまで進んでいない`}</em></strong>{`。`}</p>
    <p>{`ただ、これらはIntelのグラフィック機能を上手く使って最適化を図っている部分が多いので、`}<strong parentName="p"><em parentName="strong">{`グラフィックボードを別途積んだ場合は恩恵は受けられない可能性も高い`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "低消費電力のデスクトップ版Ryzen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BD%8E%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E3%81%AE%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E7%89%88Ryzen",
        "aria-label": "低消費電力のデスクトップ版Ryzen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`低消費電力のデスクトップ版Ryzen`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`デスクトップ版のCPUに関してはRyzenはIntelと比べ低消費電力を実現`}</em></strong>{`している。`}</p>
    <p>{`シングルコア性能を上げると電力消費が大幅に上がってしまうため、Intelは不利ではあるが、他の理由として`}<em parentName="p">{`RyzenのCPUは微細化が進みプロセスルールが細かいという点が消費電力に効いている`}</em>{`。`}</p>
    <p>{`プロセスルールは電子回路の線の幅のことであり、当然幅が狭いほど消費電力は低くなり、ワットパフォーマンスは上がってくる。そしてこの差の背景は開発体制にある。`}</p>
    <p>{`AMDはCPUの設計だけを行い、製造工程は台湾のTSMCが担う水平分業モデル。一方でIntelは一部TSMCに同様に委託しているものの、基本的には設計から製造まで全て自社で行う垂直統合モデルを採用している。`}</p>
    <p>{`TSMCはアップルやサムスンなど多くの顧客を抱えることで半導体製造のノウハウを長年に渡り蓄えてきている一方で、自身のノウハウしか持たないIntelは製造工程でその後塵を拝している。特に微細化に関する差は顕著であり、この開発体制の差がCPU性能にも現れてきていると言える。`}</p>
    <p>{`とはいえ`}<strong parentName="p"><em parentName="strong">{`ノートパソコンに関してはIntelのCPU以外の部分での最適化が消費電力の削減に貢献している面もあり五分五分`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "目的別にRyzenとIntelの優劣を比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%9B%AE%E7%9A%84%E5%88%A5%E3%81%ABRyzen%E3%81%A8Intel%E3%81%AE%E5%84%AA%E5%8A%A3%E3%82%92%E6%AF%94%E8%BC%83",
        "aria-label": "目的別にRyzenとIntelの優劣を比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`目的別にRyzenとIntelの優劣を比較`}</h2>
    <p>{`ウンチクはこの程度にしておき、それでは個々のケースにおいてRyzenとIntelのどちらがおすすめかという事を考えていきたいが、まずは結論から言うと次のような表になる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <caption>用途別、RyzenとIntelおすすめはどちらか</caption>
  <thead>
    <tr>
      <th></th>
      <th>デスクトップ</th>
      <th>ノート(GPU無)</th>
      <th>ノート(GPU有)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>ゲーム</td>
      <td colSpan="2">Intel</td>
      <td>引き分け</td>
    </tr>
    <tr>
      <td>クリエイティブ</td>
      <td>Ryzen</td>
      <td>Intel</td>
      <td>Ryzen</td>
    </tr>
    <tr>
      <td>オフィス</td>
      <td>引き分け</td>
      <td colSpan="2">Intel</td>
    </tr>
    <tr>
      <td>プログラミング</td>
      <td colSpan="3">Ryzen</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`もちろん個々のCPUにスポットを当てると当てはまらない例もあるし、価格次第という綿もあるが、ともあれ上のように考える根拠について解説していく。`}</p>
    <h3 {...{
      "id": "ゲームはIntelが有利",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AFIntel%E3%81%8C%E6%9C%89%E5%88%A9",
        "aria-label": "ゲームはIntelが有利 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームはIntelが有利`}</h3>
    <p>{`ゲームで性能に重要な要素の一つはシングルスレッドの強さであるが、`}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://amzn.to/3W5jdRx",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`第13世代Intel CPU`}</a>{`がシングルコア性能で一歩抜きんでた存在`}</em></strong>{`となっている。ベンチマークでも高いゲーム性能を示しており、消費電力はやや高いものの文句なしのNo.1である。`}</p>
    <p>{`Ryzenはマルチコア性能が優れるため、動画配信をしながらゲームをプレイするなど重い作業を同時にこなす場合は適するが、多くのケースではIntelがデスクトップではおすすめである。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`ノートに関して、GPUを搭載しない場合はIntelのPシリーズが良い`}</em></strong>{`だろう。お尻にPが付くCPUであるがPerformanceのPで3D描画機能を強化したバージョンである。ノートでありながらそこそこ3Dゲームが楽しめるレベルになる。`}</p>
    <p>{`GPUを搭載したゲーミングノートの場合はRyzen、Intelのどちらを選んでも問題ない。ベンチマーク結果を見ながらコスパの高い方を選ぼう。`}</p>
    <h3 {...{
      "id": "動画編集などクリエイティブ作業はCPU単体ではIntel、GPUとの組み合わせではRyzen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%81%AA%E3%81%A9%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%86%E3%82%A3%E3%83%96%E4%BD%9C%E6%A5%AD%E3%81%AFCPU%E5%8D%98%E4%BD%93%E3%81%A7%E3%81%AFIntel%E3%80%81GPU%E3%81%A8%E3%81%AE%E7%B5%84%E3%81%BF%E5%90%88%E3%82%8F%E3%81%9B%E3%81%A7%E3%81%AFRyzen",
        "aria-label": "動画編集などクリエイティブ作業はCPU単体ではIntel、GPUとの組み合わせではRyzen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画編集などクリエイティブ作業はCPU単体ではIntel、GPUとの組み合わせではRyzen`}</h3>
    <p>{`クリエイティブな作業には画像のレンダリングやファイルの圧縮・解凍、動画のエンコード・デコードなどマルチコア性能が有利なタスクは多い。しかしGPUや専用エンコーダ、デコーダ等の力を借りてこれらの処理を高速化することもあるのでCPUの周辺モジュールの性能も同様に重要となる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`ノートパソコンにおいてIntelはマルチコア性能でこそRyzenには及ばないが、この周辺モジュール(電子回路)により総合的にはRyzenよりも高い快適性を実現`}</em></strong>{`している。`}</p>
    <p>{`たとえば、編集した動画をYoutube投稿用など媒体に適した形に変換する時などに使われるメディアエンコーダ/デコーダの専用回路を持っており、重くなりがちな変換中のCPU負荷を軽減してくれている。`}</p>
    <p>{`この技術は`}<a href="https://www.intel.co.jp/content/www/jp/ja/architecture-and-technology/quick-sync-video/quick-sync-video-general.html" target="_blank">{`クイック・シンク・ビデオ`}</a>{`と呼ばれている。`}</p>
    <p>{`また`}<em parentName="p">{`GNA`}</em>{`(Gaussian & Neural Accelerator)と呼ばれるプロセッサはAI処理を専門に扱い、AIで良く使われる演算に特化して低消費電力でハイパフォーマンスを発揮する。`}</p>
    <p>{`Adobeで背景に映り込んだ余計なオブジェクトを消去する機能、インテリジェントなトリミングなどAI処理が使われる操作は意外と多く、このような操作がスムーズに行えるため快適な操作性を実現できる。`}</p>
    <p>{`ただディスクリートなGPUもこのような処理を得意とするので、`}<strong parentName="p"><em parentName="strong">{`BlendarやFusion、CINEMA4Dなどのソフトを用いた本格的な3Dのモデリング、映像編集作業を行うならばマルチコア性能が高いRyzenにNvidiaのGPUを搭載して処理をするのがベスト`}</em></strong>{`であろう。`}</p>
    <h3 {...{
      "id": "ノートパソコンのオフィス作業はIntel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%82%AA%E3%83%95%E3%82%A3%E3%82%B9%E4%BD%9C%E6%A5%AD%E3%81%AFIntel",
        "aria-label": "ノートパソコンのオフィス作業はIntel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンのオフィス作業はIntel`}</h3>
    <p>{`オンライン会議のTeams、オフィスソフトなどはノイズ除去の専用AIやソフトウェア最適化によりIntelの方が低消費電力で快適に作業ができる。`}</p>
    <p>{`Teamsで背景をAI処理でぼかす処理にしても、Intelの場合専用回路を使用しているのでCPU負担が軽い。Ryzenがダメという訳ではないが、`}<strong parentName="p"><em parentName="strong">{`テレワークやオンライン授業が主目的ならばIntelを選ぶべき`}</em></strong>{`だろう。`}</p>
    <p>{`実際に会社の標準PCでもRyzenが採用されているとこなど聞いたことがない。`}</p>
    <p>{`ただ、デスクトップパソコンの場合はそもそもCPUの馬力が強く、オフィス作業程度どうにでもなるので何でも良い。`}</p>
    <h3 {...{
      "id": "プログラミングや科学技術計算はRyzen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%82%84%E7%A7%91%E5%AD%A6%E6%8A%80%E8%A1%93%E8%A8%88%E7%AE%97%E3%81%AFRyzen",
        "aria-label": "プログラミングや科学技術計算はRyzen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プログラミングや科学技術計算はRyzen`}</h3>
    <p>{`プログラミングで苛立つポイントは、ソースコードを書き上げてからそれを実行可能形式までビルドする時間である。`}</p>
    <p>{`実装作業では、ソースコードを少し変えては動作を確認するという反復作業を行うため、このトライアンドエラーの度に何秒、何分も待たされるのは効率が悪い。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Ryzenのマルチコア性能を活かせば、それだけビルド時間が短縮できるためストレスのない開発体制を築くことができる`}</em></strong>{`だろう。`}</p>
    <p>{`当然Intelのソフトウェア最適化など自分が書くソースコードには適用されないため、純粋にPassMark(後述)の点数でパソコンを決めれば良いかと思われる。`}</p>
    <p>{`他、科学技術計算ソフトを多く使う人はマルチコアCPUを要する大量の演算が必要になると思われるためRyzenが有利であろう。理数系の研究者にとってRyzenのマルチコア性能は魅力的である。`}</p>
    <h2 {...{
      "id": "Ryzen-Intel性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Ryzen-Intel%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Ryzen Intel性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ryzen Intel性能比較表`}</h2>
    <p>{`CPUの周辺の専用電子回路やソフトウェア最適化の影響を測ることはできないが、CPUの純粋な性能を測るうえではベンチマークが依然重要なパラメータであり、また同じRyzen、Intel同士でCPUを選ぶときには比較は欠かせない。`}</p>
    <p>{`そこで性能比較表を準備したので参考にして欲しい。PassMark(M)で総合的な性能を、PassMark(S)でシングルコアに特化した性能を見ることができる。一般的にIntelはシングルコア性能が高く、Ryzenがマルチコア性能が高いということは下記の表からも読み解ける。`}</p>
    <BenchMark mdxType="BenchMark" />
    <CpuMatsubi mdxType="CpuMatsubi" />
    <TableDescription mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'notedesk']} cpuData={props.data.allRyzenIntelJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      